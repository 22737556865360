export default function Select({
    children,
    className,
    ...rest
}: React.SelectHTMLAttributes<HTMLSelectElement>) {
    return (
        <select
            className={`rounded-md shadow-sm border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 ${
                className ?? ""
            }`}
            {...rest}
        >
            {children}
        </select>
    );
}
